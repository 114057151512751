import { useIsFetching } from '@tanstack/react-query'
import React from 'react'

import LoadingBar from '../base/feedback/LoadingBar'

const LoadingBarContainer = () => {
  const isFetching = useIsFetching()

  return <LoadingBar isDisplayed={isFetching > 0} />
}

export default LoadingBarContainer
