import React from 'react'
import styled, { css, keyframes } from 'styled-components'

const loadingAnimation = keyframes`
  0% {
    left: 0%;
    width: 0%;
  }

  50% {
    width: 30%;
  }

  70% {
    width: 70%;
  }

  80% {
    left: 60%;
  }

  100% {
    left: 100%;
    width: 100%;
  }
`

const animation = css`
  ${loadingAnimation} 2.5s linear infinite;
`

const StyledLoading = styled('div')<{ isDisplayed: boolean }>`
  ${props => !props.isDisplayed && 'display: none;'}
  height: 0.2rem;
  width: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;

  &::before {
    display: block;
    position: absolute;
    content: '';
    height: 0.2rem;
    background-color: ${props => props.theme.colors.primary.base};
    animation: ${animation};
  }
`
interface LoadingProps {
  isDisplayed?: boolean
}

const Loading = ({ isDisplayed = false }: LoadingProps) => (
  <StyledLoading className="loading-bar" data-e2e="loading-bar" isDisplayed={isDisplayed} />
)

export default Loading
